import React from 'react';

/**
 * @returns {JSX.Element} BasketIcon svg
 */
export default function BasketIcon() {
  return (
    <svg
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.08276 17.0554L18.5588 6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.6702 17.0554L31.1941 6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d={
          'M39.4808 42.3242H10.2692C8.86117 42.2986 7.65551'
        + ' 41.3083 7.3569 39.932L2.85471 20.9799C2.61053 20.0604 2.7985 19.0798 3.36536 18.3158C3.93223'
        + ' 17.5518 4.81625 17.0876 5.76702 17.0547H43.983C44.9338 17.0876 45.8178 17.5518 46.3846'
        + ' 18.3158C46.9515 19.0798 47.1395 20.0604 46.8953 20.9799L42.3931 39.932C42.0945 41.3083 40.8888'
        + ' 42.2986 39.4808 42.3242Z'
        }
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        className='line'
        d='M15.3984 23.373V36.0078'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        className='line'
        d='M24.8743 23.373V36.0078'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        className='line'
        d='M34.3513 23.373V36.0078'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
