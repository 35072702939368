import React from 'react';

/**
 * @returns {JSX.Element} MinusIcon svg
 */
export default function MinusIcon() {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 16H26.4999'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
