import React from 'react';

/**
 * @returns {JSX.Element} InputInfoIcon svg
 */
export default function InputInfoIcon() {
  return (
    <svg
      className='info-icon'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 2C22.1797 2 28 7.8203 28 15C28 22.1797 22.1797 28 15 28C7.8203 28 2 22.1797 2 15C2 7.8203 7.8203 2 15 2Z'
        stroke='#00B0CA'
        strokeWidth='3'
      />
      <rect
        x='13'
        y='13'
        width='4'
        height='9'
        rx='1'
        fill='white'
      />
      <ellipse
        cx='15'
        cy='9'
        rx='2'
        ry='2'
        fill='white'
      />
    </svg>

  );
}
