import React from 'react';

/**
 * @returns {JSX.Element} EmptyCart svg
 */
export default function EmptyCart() {
  return (
    <svg
      viewBox='0 0 304 304'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d={
          'M80.0088 245.459L70.4736 259.47C68.9515 261.714 69.8339 264.784 72.3156 265.869C83.1192 270.588'
        + ' 111.829 281.204 150.665 280.9C189.567 280.597 213.926 270.83 222.766 266.542C224.89 265.511 225.788'
        + ' 262.97 224.796 260.832L217.268 244.561L80.0088 245.459Z'
        }
        fill='#E60000'
      />
      <path
        d={
          'M151.007 51.5293C151.007 51.5293 151.012 51.5293 151.018 51.5348C168.108 62.52 178.184 85.0195'
        + ' 178.184 106.587V220.656V238.801'
        }
        stroke='#AA0000'
        strokeWidth='0.64135'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M123.267 239.082V220.369V106.582C123.267 85.7908 132.526 62.6244 148.519 51.529V51.5234'
        stroke='#AA0000'
        strokeWidth='0.64135'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M104.01 71.5803C104.01 66.9283 124.117 63.1582 148.929 63.1582C173.74 63.1582 193.847 66.9283 193.847 71.5803'
        stroke='#AA0000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
      />
      <path
        d='M81.6694 129.903C81.6694 123.9 111.808 119.033 148.989 119.033C186.17 119.033 216.309 123.9 216.309 129.903'
        stroke='#AA0000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M126.45 168.379V127.156C126.45 126.787 126.456 126.417 126.467 126.054C126.72 117.692 130.316 109.783'
        + ' 136.195 103.83L138.555 101.443C141.886 98.0754 144.39 93.9746 145.868 89.4769L148.984 80.002'
        }
        stroke='#FF7F00'
        strokeWidth='2.5654'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M173.16 178.026V131.572C173.16 131.158 173.155 130.739 173.144 130.326C172.89 120.906 169.294 111.988'
        + ' 163.415 105.285L161.055 102.596C157.724 98.798 155.22 94.1846 153.742 89.1137L150.626 78.4316'
        }
        stroke='#FFA300'
        strokeWidth='3.20675'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M158.651 40.8957C158.651 45.1564 155.48 48.6785 151.371 49.2407C154.736 48.7281 157.311 45.8233 157.311'
        + ' 42.3123C157.311 38.4429 154.173 35.3067 150.302 35.3067C146.43 35.3067 143.292 38.4484 143.292 42.3123C143.292'
        + ' 46.1596 146.386 49.2738 150.219 49.3179C145.559 49.3179 141.787 45.5477 141.787 40.8902C141.787 36.2272 145.559'
        + ' 32.457 150.219 32.457C154.873 32.4625 158.651 36.2327 158.651 40.8957Z'
        }
        fill='#E60000'
      />
      <path
        d={
          'M216.315 244.704C216.315 245.795 215.322 246.842 213.48 247.834C210.303 249.549 204.579 251.075 197.035'
        + ' 252.316C191.487 253.231 184.957 253.98 177.711 254.537C169.273 255.176 159.881 255.546 149.965 255.568C149.646'
        + ' 255.573 149.315 255.573 148.995 255.573C140.436 255.573 132.246 255.314 124.713 254.846C117.031 254.366 110.032'
        + ' 253.666 104.01 252.795C94.9437 251.478 88.1053 249.775 84.5041 247.84C82.6566 246.848 81.6694 245.801 81.6694'
        + ' 244.709C81.6694 241.501 90.2947 238.613 104.01 236.618C110.032 235.741 117.031 235.047 124.713 234.567C132.246'
        + ' 234.099 140.436 233.84 148.995 233.84C149.315 233.84 149.646 233.84 149.965 233.845C159.881 233.867 169.273 234.231'
        + ' 177.711 234.876C184.957 235.433 191.487 236.182 197.035 237.097C208.958 239.049 216.315 241.733 216.315 244.704Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M134.711 204.765C135.141 205.085 134.86 206.154 132.4 208.177C132.4 208.177 135.654 211.793 133.409'
        + ' 215.056C133.409 215.056 133.194 213.171 132.83 212.807C132.031 212.079 134.209 216.29 129.852 220.138C129.852 220.138'
        + ' 130.724 217.233 130.867 215.64C130.867 215.64 131.01 220.573 124.624 223.263C124.624 223.263 126.223 221.014 126.803'
        + ' 219.123C126.803 219.123 123.825 224.492 116.782 224.927C109.74 225.363 107.705 222.607 107.705 222.607C107.705 222.607'
        + ' 112.133 223.114 114.968 221.372C114.968 221.372 113.589 221.516 111.554 221.008C111.554 221.008 118.232 219.482 119.975'
        + ' 217.74C119.975 217.74 117.924 218.986 116.346 218.126C116.346 218.126 119.948 216.538 123.268 214.642C125.744 213.231'
        + ' 128.093 209.902 126.648 210.85C124.856 212.03 124.183 212.151 124.183 212.151C124.183 212.151 126.422 207.846 131.578'
        + ' 207.703C131.584 207.703 134.165 204.357 134.711 204.765Z'
        }
        fill='#FFA300'
      />
      <path
        d={
          'M132.406 208.176C132.406 208.176 135.781 211.61 133.415 215.055C133.415 215.055 133.162 212.684 132.676 212.734C132.676'
        + ' 212.734 133.454 217.21 129.864 220.131L130.283 218.18C130.283 218.18 129.119 221.426 124.63 223.256L126.555 219.525C126.555'
        + ' 219.525 123.803 224.359 116.788 224.926C109.773 225.494 107.71 222.606 107.71 222.606C107.71 222.606 112.387 224.48 115.382'
        + ' 223.626C115.382 223.626 117.091 223.411 118.691 221.757C118.691 221.757 118.15 223.102 117.676 223.73C117.014 224.607 120.45'
        + ' 223.091 121.895 219.944C121.895 219.944 122.253 221.09 121.63 222.507C120.896 224.166 123.692 221.123 124.663 219.828C124.663'
        + ' 219.828 124.338 220.572 124.619 220.798C124.619 220.798 127.354 219.095 127.354 216.846C127.354 216.846 128.783 218.45 127.713'
        + ' 220.263C127.713 220.263 130.117 217.116 129.582 214.288C129.582 214.288 130.774 216.576 130.172 217.755C130.172 217.755 131.954'
        + ' 214.818 131.292 212.458C131.292 212.458 132.307 214.818 131.832 216.14C131.832 216.14 132.378 212.519 132.296 210.75C132.296'
        + ' 210.75 132.996 212.018 133.288 212.436C133.288 212.431 133.371 209.46 132.406 208.176Z'
        }
        fill='#FF7F00'
      />
      <path
        d={
          'M130.906 208.486C130.906 208.486 128.689 208.944 127.387 209.787C127.387 209.787 129.301 209.517 129.759 209.787C130.217'
        + ' 210.057 127.696 210.972 127.696 210.972C127.696 210.972 128.077 211.738 128.689 211.43C129.301 211.127 125.358 214.56 123.875'
        + ' 214.853C123.875 214.853 125.137 215.293 126.014 214.853C126.014 214.853 119.998 217.267 118.178 217.697C118.178 217.697 121.58'
        + ' 217.201 122.882 216.589C122.882 216.589 119.441 219.951 116.27 220.486C116.27 220.486 123.841 218.308 126.742 215.023C126.742'
        + ' 215.023 127.316 214.373 127.889 213.645C127.889 213.645 127.2 213.916 126.13 214.296C126.13 214.296 129.191 211.926 129.61'
        + ' 211.198C129.61 211.198 129.075 211.198 128.386 211.082C128.386 211.082 130.338 210.206 130.718 209.633C130.718 209.633 129.858'
        + ' 209.39 129.229 209.291L130.906 208.486Z'
        }
        fill='#FFD07D'
      />
      <path
        d={
          'M213.473 247.829C210.297 249.543 204.572 251.07 197.028 252.31C191.48 253.225 184.951 253.975 177.704 254.531C169.266'
        + ' 255.171 159.875 255.54 149.959 255.562C149.639 255.568 149.308 255.568 148.988 255.568C140.429 255.568 132.24 255.309 124.706'
        + ' 254.84C117.024 254.36 110.026 253.66 104.004 252.79C94.9372 251.472 88.0988 249.769 84.4976 247.834C92.825 243.353 118.546'
        + ' 240.096 148.983 240.096C179.419 240.096 205.146 243.348 213.473 247.829Z'
        }
        fill='#AA0000'
      />
      <path
        d={
          'M84.6641 247.918C84.6641 247.918 92.9419 239.959 145.625 240.114C145.625 240.114 149.519 248.789 163.284 250.283C177.054'
        + ' 251.777 173.371 258.843 173.371 258.843C173.371 258.843 88.7506 254.902 87.256 251.363C85.756 247.83 84.6641 247.918 84.6641 247.918Z'
        }
        fill='#FF7F00'
      />
      <path
        d='M81.6694 188.668C81.6694 182.666 111.808 177.799 148.989 177.799C186.17 177.799 216.309 182.666 216.309 188.668'
        stroke='#AA0000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M216.315 188.67C216.315 194.672 186.176 199.539 148.995 199.539C111.814 199.539 81.6753 194.672 81.6753 188.67'
        stroke='#E60000'
        strokeWidth='6.4135'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M149.711 51.5231C149.612 51.5231 149.524 51.5231 149.458 51.5231C149.303 51.5176 149.143 51.5176 148.989 51.5176'
        stroke='#E60000'
        strokeWidth='0.64135'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M149.71 51.5234C149.738 51.5234 149.765 51.5234 149.798 51.5234C149.826 51.5234 149.842 51.5234 149.87 51.5234C149.897'
        + ' 51.5234 149.931 51.5234 149.958 51.5234C150.041 51.5234 150.124 51.5234 150.212 51.529H150.311C150.388 51.529 150.454 51.529'
        + ' 150.526 51.529C150.818 51.5345 151.116 51.5345 151.348 51.5565C151.403 51.562 151.458 51.562 151.502 51.562C151.546 51.562'
        + ' 151.579 51.573 151.613 51.573C151.618 51.573 151.624 51.573 151.629 51.573H151.64C151.673 51.5786 151.706 51.5786 151.728'
        + ' 51.5786C179.606 55.1337 197.027 87.4609 197.027 118.052V137.509'
        }
        stroke='#E60000'
        strokeWidth='5.1308'
        strokeMiterlimit='10'
      />
      <path
        d='M197.029 196.281V242.664V258.35'
        stroke='#E60000'
        strokeWidth='5.1308'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M151.29 51.5566C151.312 51.5566 151.328 51.5566 151.35 51.5566C151.405 51.5622 151.455 51.5622 151.505 51.5622C151.543'
        + ' 51.5622 151.576 51.5677 151.609 51.5677C151.615 51.5677 151.62 51.5677 151.631 51.5677H151.637C151.67 51.5732 151.703'
        + ' 51.5732 151.731 51.5732'
        }
        stroke='#E60000'
        strokeWidth='0.64135'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M104.01 258.836V242.173V118.046C104.01 87.3999 121.305 51.942 149.458 51.5176C149.524 51.5176 149.613 51.5176'
        + ' 149.712 51.5176C149.739 51.5176 149.767 51.5176 149.8 51.5176C149.828 51.5176 149.844 51.5176 149.872 51.5176C149.899'
        + ' 51.5176 149.932 51.5176 149.96 51.5231C150.043 51.5231 150.125 51.5231 150.214 51.5231H150.313C150.346 51.5286 150.374'
        + ' 51.5286 150.407 51.5286C150.451 51.5286 150.495 51.5286 150.539 51.5286C150.561 51.5286 150.572 51.5286 150.594'
        + ' 51.5286C150.627 51.5341 150.655 51.5341 150.688 51.5341C150.793 51.5341 150.898 51.5396 150.997 51.5396C151.102'
        + ' 51.5451 151.195 51.5451 151.289 51.5451'
        }
        stroke='#E60000'
        strokeWidth='5.1308'
        strokeMiterlimit='10'
      />
      <path
        d={
          'M125.578 171.848C125.578 174.483 124.668 176.632 123.521 176.737C123.494 176.743 123.466 176.743 123.438'
        + ' 176.743H123.433C123.312 176.743 123.196 176.721 123.08 176.676C123.052 176.665 123.019 176.654 122.986'
        + ' 176.638C122.986 176.638 122.986 176.638 122.981 176.638C122.016 176.169 121.288 174.202 121.288 171.854C121.288'
        + ' 169.274 122.159 167.157 123.267 166.976C123.289 166.97 123.317 166.97 123.334 166.964C123.367 166.959 123.394'
        + ' 166.959 123.427 166.959C123.576 166.959 123.72 166.998 123.858 167.064C123.88 167.075 123.907 167.086 123.929'
        + ' 167.102C124.883 167.609 125.578 169.544 125.578 171.848Z'
        }
        fill='#FFA300'
      />
      <path
        d={
          'M176.199 181.245C176.199 183.941 175.245 186.134 174.059 186.14H174.054L173.701 186.074L123.516'
        + ' 176.737L122.986 176.632C122.986 176.632 122.986 176.632 122.981 176.632C122.016 176.163 121.288 174.196'
        + ' 121.288 171.848C121.288 171.666 121.293 171.495 121.304 171.318C121.398 173.446 122.082 175.171 122.981'
        + ' 175.612C122.986 175.612 122.986 175.612 122.986 175.612L123.516 175.717L173.701 185.054L174.054 185.12H174.059C175.157'
        + ' 185.115 176.067 183.213 176.182 180.771C176.193 180.92 176.199 181.08 176.199 181.245Z'
        }
        fill='#FF7F00'
      />
      <path
        d={
          'M176.189 180.76C176.073 183.202 175.169 185.104 174.066 185.109H174.06L173.707 185.043L123.522 175.706L122.993'
        + ' 175.601C122.993 175.601 122.993 175.601 122.987 175.601C122.088 175.166 121.404 173.435 121.311 171.308C121.421 168.97'
        + ' 122.248 167.13 123.274 166.964C123.296 166.959 123.323 166.959 123.34 166.953L123.864 167.052L173.895 176.362L174.556'
        + ' 176.483C175.439 176.974 176.101 178.677 176.189 180.76Z'
        }
        fill='#FFA300'
      />
      <path
        d={
          'M176.68 181.244C176.68 183.939 175.406 186.133 173.828 186.139H173.823C173.663 186.139 173.509 186.117 173.354'
        + ' 186.073C172.003 185.687 170.972 183.669 170.972 181.244C170.972 178.665 172.135 176.548 173.608 176.366C173.679 176.355'
        + ' 173.751 176.35 173.817 176.35C174.049 176.35 174.27 176.399 174.49 176.487C175.753 177.006 176.68 178.94 176.68 181.244Z'
        }
        fill='#FFD07D'
      />
      <path
        d='M121.371 170.639L171.925 179.998L172.571 178.719L121.602 169.344C121.597 169.349 121.42 169.967 121.371 170.639Z'
        fill='#FFD07D'
      />
      <path
        d='M121.84 168.596L153.561 174.113L122.529 167.416C122.529 167.416 122.082 167.835 121.84 168.596Z'
        fill='#FFD07D'
      />
      <path
        d='M149.959 51.5293V255.568'
        stroke='#E60000'
        strokeWidth='5.1308'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d={
          'M148.95 80.0039C123.217 80.0039 98.9742 76.6306 101.439 70.3526C113.903 58.0005 130.596 50.4492'
        + ' 148.95 50.4492C149.11 50.4492 149.281 50.4492 149.441 50.4602H149.871C149.898 50.4602 149.937 50.4602'
        + ' 149.959 50.4658H150.224C150.29 50.4658 150.356 50.4658 150.428 50.4768H150.621C150.654 50.4823 150.681'
        + ' 50.4823 150.715 50.4823C150.825 50.4823 150.93 50.4878 151.034 50.4878C151.15 50.4988 151.244 50.4988'
        + ' 151.343 50.4988H151.404C151.459 50.5043 151.514 50.5043 151.564 50.5043C151.608 50.5043 151.641 50.5154 151.674'
        + ' 50.5154H151.702C151.735 50.5209 151.773 50.5209 151.801 50.5209C169.123 51.265 184.818 58.7281 196.659 70.5565C198.793'
        + ' 76.0023 174.688 80.0039 148.95 80.0039Z'
        }
        fill='#E60000'
      />
      <path
        d='M194.971 70.8867C208.107 83.994 216.313 102.657 216.313 123.371V251.362'
        stroke='#E60000'
        strokeWidth='3.8481'
        strokeMiterlimit='10'
      />
      <path
        d='M81.6694 251.368V123.371C81.6694 102.558 89.9527 83.8175 103.199 70.6992'
        stroke='#E60000'
        strokeWidth='3.8481'
        strokeMiterlimit='10'
      />
      <path
        d='M216.315 129.9C216.315 135.903 186.176 140.77 148.995 140.77C111.814 140.77 81.6753 135.903 81.6753 129.9'
        stroke='#E60000'
        strokeWidth='6.4135'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M198.92 76.1504C198.92 81.326 176.535 85.5261 148.922 85.5261C121.31 85.5261 98.9248 81.3316 98.9248 76.1504'
        stroke='#E60000'
        strokeWidth='2.5654'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d={
          'M216.315 244.703V250.965C216.315 256.967 186.171 261.829 148.995 261.829C111.819 261.829 81.6753 256.967'
        + ' 81.6753 250.965V244.703C81.6753 245.794 82.668 246.842 84.5099 247.834C88.1111 249.769 94.9495 251.472 104.016'
        + ' 252.789C110.038 253.665 117.037 254.36 124.719 254.839C132.252 255.308 140.442 255.567 149.001 255.567C149.321'
        + ' 255.567 149.651 255.567 149.971 255.562C159.887 255.539 169.279 255.176 177.716 254.531C184.963 253.974 191.493'
        + ' 253.224 197.041 252.31C204.59 251.075 210.309 249.543 213.486 247.828C215.322 246.842 216.315 245.789 216.315 244.703Z'
        }
        fill='#E60000'
        stroke='#E60000'
        strokeWidth='1.92405'
        strokeMiterlimit='10'
      />
      <path
        d='M216.353 188.565V188.389'
        stroke='#E60000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M216.353 131.62V131.234'
        stroke='#E60000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M220.153 131.339C220.153 128.814 231.756 126.77 246.078 126.77C260.4 126.77 272.015 128.814 272.015 131.339'
        stroke='#E60000'
        strokeWidth='2.5654'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M220.208 188.386V131.619C220.175 131.525 220.159 131.432 220.159 131.338'
        stroke='#E60000'
        strokeWidth='1.92405'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M272.013 188.667C272.013 186.148 260.399 184.104 246.077 184.104C232.295 184.104 221.028 185.994 220.201 188.386'
        stroke='#E60000'
        strokeWidth='2.5654'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M272.015 131.338V188.667'
        stroke='#E60000'
        strokeWidth='1.92405'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M246.084 126.768V183.595'
        stroke='#E60000'
        strokeWidth='1.92405'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d={
          'M218.36 144.723C217.765 144.723 217.274 144.232 217.274 143.637V138.467C217.274'
        + ' 137.871 217.765 137.381 218.36 137.381C218.956 137.381 219.447 137.871 219.447'
        + ' 138.467V143.637C219.447 144.238 218.956 144.723 218.36 144.723Z'
        }
        stroke='#E60000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d={
          'M218.36 181.471C217.765 181.471 217.274 180.98 217.274 180.385V175.215C217.274'
        + ' 174.619 217.765 174.129 218.36 174.129C218.956 174.129 219.447 174.619 219.447'
        + ' 175.215V180.385C219.447 180.986 218.956 181.471 218.36 181.471Z'
        }
        stroke='#E60000'
        strokeWidth='1.2827'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d={
          'M146.325 49.7861C146.325 49.7861 144.102 54.5483 156.014 52.6578C156.014 52.6578 158.855'
        + ' 52.0625 160.073 51.39C160.073 51.39 153.235 49.0364 146.325 49.7861Z'
        }
        fill='#AA0000'
      />
      <path
        d={
          'M149.98 52.058C152.234 52.058 154.061 50.2318 154.061 47.9792C154.061 45.7265 152.234 43.9004'
        + ' 149.98 43.9004C147.727 43.9004 145.899 45.7265 145.899 47.9792C145.899 50.2318 147.727 52.058 149.98 52.058Z'
        }
        fill='#FF7F00'
      />
      <path
        d={
          'M158.238 209.069C157.708 209.46 158.056 210.772 161.072 213.258C161.072 213.258 157.079 217.695 159.837'
        + ' 221.702C159.837 221.702 160.102 219.387 160.543 218.941C161.524 218.048 158.85 223.218 164.199 227.942C164.199'
        + ' 227.942 163.129 224.375 162.953 222.419C162.953 222.419 162.776 228.476 170.618 231.772C170.618 231.772 168.655'
        + ' 229.011 167.944 226.696C167.944 226.696 171.6 233.288 180.247 233.823C188.895 234.357 191.393 230.973 191.393'
        + ' 230.973C191.393 230.973 185.955 231.596 182.481 229.457C182.481 229.457 184.174 229.634 186.672 229.011C186.672'
        + ' 229.011 178.472 227.142 176.332 225.004C176.332 225.004 178.852 226.531 180.782 225.478C180.782 225.478 176.359'
        + ' 223.527 172.289 221.201C169.251 219.464 166.367 215.386 168.137 216.549C170.337 217.993 171.159 218.141 171.159'
        + ' 218.141C171.159 218.141 168.413 212.861 162.081 212.679C162.076 212.679 158.91 208.573 158.238 209.069Z'
        }
        fill='#FFA300'
      />
      <path
        d={
          'M161.072 213.258C161.072 213.258 156.925 217.474 159.836 221.702C159.836 221.702 160.145 218.792'
        + ' 160.746 218.858C160.746 218.858 159.792 224.348 164.199 227.941L163.68 225.544C163.68 225.544 165.109'
        + ' 229.529 170.618 231.772L168.252 227.192C168.252 227.192 171.633 233.128 180.241 233.823C188.85 234.517'
        + ' 191.387 230.973 191.387 230.973C191.387 230.973 185.64 233.277 181.973 232.23C181.973 232.23 179.877 231.965'
        + ' 177.909 229.937C177.909 229.937 178.57 231.59 179.155 232.362C179.971 233.437 175.747 231.574 173.977 227.71C173.977'
        + ' 227.71 173.535 229.115 174.302 230.852C175.201 232.886 171.771 229.154 170.579 227.561C170.579 227.561 170.976 228.476'
        + ' 170.629 228.752C170.629 228.752 167.27 226.657 167.27 223.901C167.27 223.901 165.517 225.869 166.829 228.096C166.829'
        + ' 228.096 163.879 224.232 164.535 220.759C164.535 220.759 163.068 223.565 163.807 225.015C163.807 225.015 161.623 221.404'
        + ' 162.434 218.511C162.434 218.511 161.188 221.404 161.772 223.03C161.772 223.03 161.099 218.582 161.204 216.411C161.204'
        + ' 216.411 160.344 217.97 159.985 218.483C159.991 218.483 159.881 214.84 161.072 213.258Z'
        }
        fill='#FF7F00'
      />
      <path
        d={
          'M162.908 213.643C162.908 213.643 165.633 214.205 167.226 215.236C167.226 215.236 164.883 214.905 164.314 215.236C163.746'
        + ' 215.566 166.851 216.691 166.851 216.691C166.851 216.691 166.383 217.628 165.633 217.253C164.883 216.878 169.719 221.1 171.544'
        + ' 221.453C171.544 221.453 169.995 221.993 168.914 221.453C168.914 221.453 176.298 224.413 178.532 224.942C178.532 224.942 174.357'
        + ' 224.33 172.758 223.58C172.758 223.58 176.982 227.709 180.876 228.365C180.876 228.365 171.583 225.692 168.015 221.657C168.015'
        + ' 221.657 167.309 220.858 166.609 219.97C166.609 219.97 167.452 220.301 168.765 220.769C168.765 220.769 165.009 217.859 164.496'
        + ' 216.972C164.496 216.972 165.153 216.972 165.997 216.828C165.997 216.828 163.603 215.748 163.134 215.048C163.134 215.048 164.188'
        + ' 214.756 164.965 214.624L162.908 213.643Z'
        }
        fill='#FFD07D'
      />
      <path
        d={
          'M269.103 150.473C268.265 150.473 267.515 150.814 266.963 151.366C266.417 151.917 266.075 152.677 266.075'
        + ' 153.504V162.538C266.075 164.208 267.437 165.57 269.103 165.57C270.774 165.57 272.136 164.208 272.136 162.538V153.504C272.136'
        + ' 151.84 270.774 150.473 269.103 150.473ZM269.765 158.388V159.611C269.765 159.975 269.467 160.278 269.103 160.278C268.739 160.278'
        + ' 268.441 159.975 268.441 159.611V158.388C268.005 158.151 267.713 157.693 267.713 157.164C267.713 156.398 268.336 155.77 269.108'
        + ' 155.77C269.88 155.77 270.504 156.392 270.504 157.164C270.498 157.693 270.2 158.151 269.765 158.388Z'
        }
        fill='#E60000'
      />
      <path
        d={
          'M269.103 150.473C268.265 150.473 267.515 150.814 266.963 151.366C266.417 151.917 266.075 152.677 266.075'
        + ' 153.504V162.538C266.075 164.208 267.437 165.57 269.103 165.57C270.774 165.57 272.136 164.208 272.136 162.538V153.504C272.136'
        + ' 151.845 270.774 150.473 269.103 150.473ZM269.765 158.388V159.611C269.765 159.975 269.467 160.278 269.103 160.278C268.739 160.278'
        + ' 268.441 159.975 268.441 159.611V158.388C268.005 158.151 267.713 157.693 267.713 157.164C267.713 156.398 268.336 155.77 269.108'
        + ' 155.77C269.88 155.77 270.504 156.392 270.504 157.164C270.498 157.693 270.2 158.156 269.765 158.388Z'
        }
        fill='#E60000'
      />
      <path
        d='M272.137 157.159C272.137 157.159 276.532 154.375 278.677 158.658'
        stroke='#E60000'
        strokeWidth='3.20675'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d={
          'M150.986 173.485L154.852 173.473C155.563 173.473 156.142 172.895 156.142 172.184V166.225C156.142 165.509'
        + ' 155.563 164.93 154.846 164.936L150.986 164.947V173.485Z'
        }
        stroke='#E60000'
        strokeWidth='3.20675'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d={
          'M130.57 79.3911C130.57 79.3911 118.614 73.8957 123.875 64.5862C126.792 59.4215 146.326 49.7812 146.326 49.7812C146.326 49.7812'
        + ' 123.042 52.0411 109.928 63.1531C109.928 63.1531 103.894 67.5074 101.44 70.3461C101.446 70.3516 97.2877 77.1643 130.57 79.3911Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M156.929 254.729C156.929 254.729 143.589 260.505 120.983 260.842C98.3725 261.178 89.3116 272.086 89.3116 272.086C89.3116 272.086'
        + ' 77.3389 268.332 72.3203 265.868C67.3018 263.405 70.4122 259.563 71.3442 258.196C72.2762 256.829 80.0136 245.458 80.0136 245.458L80.2728'
        + ' 235.156C80.2728 235.156 81.4198 244.234 83.0798 245.899C83.9236 246.742 85.4677 247.635 87.4586 248.341C104.373 254.293 152.804 255.181'
        + ' 156.929 254.729Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M159.919 280.646C159.919 280.646 211.378 269.617 214.295 263.592C217.213 257.568 218.784 247.828 218.784 247.828L224.801'
        + ' 260.836C224.801 260.836 226.56 264.816 222.772 266.546C218.983 268.277 198.308 278.854 159.919 280.646Z'
        }
        fill='#AA0000'
      />
      <path
        d={
          'M92.434 81.3984C92.434 81.3984 85.1654 92.9734 82.9705 102.536C80.6322 112.711 79.7167 120.472 80.3013 127.808C80.3013'
        + ' 127.808 82.7389 107.773 85.3088 99.4498C87.8787 91.1269 92.434 81.3984 92.434 81.3984Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M85.2925 130.713C85.2925 130.713 88.3257 132.978 102.091 135.265V118.603C102.091 118.603 102.962 132.774 103.486'
        + ' 134.091C104.01 135.409 105.935 135.839 105.935 135.839C105.935 135.839 132.279 138.92 147.859 138.231L148.046 106.637C148.046'
        + ' 106.637 148.945 131.027 149.723 134.466C150.5 137.906 152.061 138.336 152.061 138.336C152.061 138.336 173.889 137.988 181.477'
        + ' 137.046C181.477 137.046 153.462 142.128 135.902 141.191C118.343 140.254 96.234 136.324 91.982 134.841C87.7245 133.353 85.2925'
        + ' 130.713 85.2925 130.713Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M85.2925 189.49C85.2925 189.49 88.3257 191.755 102.091 194.043V177.38C102.091 177.38 103.486 184.331 104.01'
        + ' 185.648C104.534 186.965 105.929 194.616 105.929 194.616C105.929 194.616 132.274 197.697 147.853 197.008L148.04'
        + ' 165.414C148.04 165.414 148.939 189.804 149.717 193.244C150.495 196.683 152.055 197.113 152.055 197.113C152.055 197.113'
        + ' 173.883 196.766 181.472 195.823L194.977 194.109C190.036 199.185 145.515 200.481 135.902 199.968C118.343 199.031 96.3277'
        + ' 194.792 91.982 193.618C85.7502 191.932 85.2925 189.49 85.2925 189.49Z'
        }
        fill='#FF4436'
      />
      <path
        d={
          'M146.915 50.6687C146.915 50.6687 147.18 47.8576 149.027 46.871C150.88 45.8844 153.39 45.7356 153.39 45.7356C153.39'
        + ' 45.7356 151.095 42.2851 147.378 44.8316C147.384 44.8316 144.367 47.1742 146.915 50.6687Z'
        }
        fill='#FFD07D'
      />
      <path
        d={
          'M142.172 43.4368C142.172 43.4368 141.698 38.7572 146.959 35.1029C152.22 31.4485 155.733 34.5131 155.733 34.5131C155.733'
        + ' 34.5131 150.753 29.9327 144.896 34.3422C144.896 34.3477 140.429 37.5115 142.172 43.4368Z'
        }
        fill='#FF4436'
      />
    </svg>

  );
}
