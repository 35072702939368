import React from 'react';

/**
 * @returns {JSX.Element} SuccessTickIcon svg
 */
export default function SuccessTickIcon() {
  return (
    <svg
      viewBox='0 0 122 122'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        stroke='none'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61 122C94.6894 122 122 94.6894 122 61C122 27.3106 94.6894 0 61 0C27.3106 0 0 27.3106 0 61C0 94.6894 27.3106 122 61 122Z'
        fill='#00B0CA'
      />
      <path
        d='M39.1416 62.0834L49.1307 76.8702C49.7612 77.8515 50.8078 78.4549 51.9407 78.4902C53.0735 78.5254 54.1528 77.9882 54.8384 77.0479L84.3833 45.6636'
        stroke='#F2F4F7'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
