import React from 'react';

/**
 * @returns {JSX.Element} CopyIcon svg
 */
export default function CopyIcon() {
  return (
    <svg
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 9C5 6.79086 6.79086 5 9 5H16.75C18.9591 5 20.75 6.79086 20.75 9V19.375C20.75 21.5841 18.9591 23.375 16.75 23.375H9C6.79086 23.375 5 21.5841 5 19.375V9Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />

      <path
        d='M15.3125 1.625H9.25C4.83172 1.625 1.25 5.20672 1.25 9.625V18.6875'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
