import React from 'react';

/**
 * @returns {JSX.Element} ShareIcon svg
 */
export default function ShareIcon() {
  return (
    <svg
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.84882 20.0341C5.02413 20.0341 4.29919 19.4879 4.07181 18.6952C3.84443 17.9024 4.16962 17.055 4.86892 16.6178L25.4034 3.7814C26.0259 3.39184 26.82 3.40792 27.4262 3.82234C28.0325 4.23677 28.3357 4.97087 28.1987 5.69231L24.1991 26.9934C24.0635 27.7135 23.5154 28.2855 22.8018 28.4517C22.0882 28.618 21.3438 28.3471 20.9039 27.7611L15.1156 20.0341H5.84882Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1154 20.0342H12.3379V25.7791C12.3378 26.464 12.7167 27.0928 13.3222 27.4128C13.9278 27.7328 14.6606 27.6915 15.2265 27.3056L18.7572 24.8992L15.1154 20.0342Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1147 20.0346L27.4246 3.8252'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
