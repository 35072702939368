import React from 'react';

/**
 * @returns {JSX.Element} PlusIcon svg
 */
export default function PlusIcon() {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 15.8516H27.7037H4Z'
      />
      <path
        d='M4 15.8516H27.7037'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8523 4V27.7037V4Z'
      />
      <path
        d='M15.8523 4V27.7037'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
