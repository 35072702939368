import React from 'react';

/**
 * @returns {JSX.Element} FlagIcon svg
 */
export default function FlagIcon() {
  return (
    <svg
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.32812 40.7812V4.92188'
        strokeWidth='2.8125'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.32812 29.4317L10.8866 28.0297C14.7068 26.8536 18.8484 27.3803 22.2526 29.475C25.5728 31.5215 29.6001 32.0784 33.3511 31.01L37.6683 29.7757C38.3665 29.5762 38.8478 28.938 38.8477 28.2118V11.2626C38.8472 10.7526 38.6076 10.2723 38.2005 9.96507C37.7933 9.65786 37.2657 9.55931 36.7751 9.69882L33.3511 10.6773C29.6013 11.7484 25.5741 11.1945 22.2526 9.15105C18.8484 7.05629 14.7068 6.52964 10.8866 7.70574L6.32812 9.10624'
        strokeWidth='2.8125'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
