import React from 'react';

/**
 * @returns {JSX.Element} WarningIcon SVG
 */
export default function WarningIcon() {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM17.5 17.5C17.5 18.0523 17.0523 18.5 16.5 18.5H15.5C14.9477 18.5 14.5 18.0523 14.5 17.5V7.5C14.5 6.94771 14.9477 6.5 15.5 6.5H16.5C17.0523 6.5 17.5 6.94772 17.5 7.5V17.5ZM16 21C14.8954 21 14 21.8954 14 23C14 24.1046 14.8954 25 16 25C17.1046 25 18 24.1046 18 23C18 21.8954 17.1046 21 16 21Z'
      />
    </svg>
  );
}
