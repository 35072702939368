import React from 'react';

/**
 * @returns {JSX.Element} CartIcon svg
 */
export default function CartIcon() {
  const strokeWidth = '2';
  const lineStyle = 'round';

  return (
    <svg
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d={
          'M21.4216 23.6219H9.14816C8.24305 23.622 7.46991 22.9691 7.31846 22.0767L4.05617'
        + ' 2.90135C3.90418 2.00949 3.13119 1.35723 2.22647 1.35742H1.00914'
        }
        strokeWidth={ strokeWidth }
        strokeLinecap={ lineStyle }
        strokeLinejoin={ lineStyle }
      />
      <path
        d={
          'M19.104 26.4062C18.8477 26.4062 18.64 26.614 18.64 26.8702C18.64 27.1264 18.8477'
        + ' 27.3341 19.104 27.3341C19.3602 27.3341 19.5679 27.1264 19.5679 26.8702C19.5679 26.614'
        + ' 19.3602 26.4062 19.104 26.4062'
        }
        strokeWidth={ strokeWidth }
        strokeLinecap={ lineStyle }
        strokeLinejoin={ lineStyle }
      />
      <path
        d={
          'M9.82539 26.4062C9.56917 26.4062 9.36147 26.614 9.36147 26.8702C9.36147 27.1264'
        + ' 9.56917 27.3341 9.82539 27.3341C10.0816 27.3341 10.2893 27.1264 10.2893 26.8702C10.2893'
        + ' 26.614 10.0816 26.4062 9.82539 26.4062'
        }
        strokeWidth={ strokeWidth }
        strokeLinecap={ lineStyle }
        strokeLinejoin={ lineStyle }
      />
      <path
        d={
          'M6.63537 18.055H21.5686C23.2713 18.0549 24.7555 16.8961 25.1687 15.2443L26.9625'
        + ' 8.06901C27.0319 7.79173 26.9695 7.49795 26.7936 7.2727C26.6176 7.04744 26.3477 6.91586'
        + ' 26.0619 6.91602H4.74134'
        }
        strokeWidth={ strokeWidth }
        strokeLinecap={ lineStyle }
        strokeLinejoin={ lineStyle }
      />
    </svg>

  );
}
