import React from 'react';

/**
 * @returns {JSX.Element} ArrowDown svg
 */
export default function ArrowDown() {
  return (
    <svg
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9874 8.78687L12.512 16.2623L5.03661 8.78686'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
