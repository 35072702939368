import React from 'react';

/**
 * @returns {JSX.Element} CloseIcon svg
 */
export default function CloseIcon() {
  return (
    <svg
      viewBox='0 0 23 23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.13989 1L21.6058 20.9994M21.6064 1L1.1405 20.9994'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
