import React from 'react';

/**
 * @returns {JSX.Element} VoucherIcon svg
 */
export default function VoucherIcon() {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d={
          'M36.0505 17.2803C34.8565 15.0015'
        + ' 35.8859 12.0391 38.3497 10.6636L35.6474 5.5058C35.0504 4.36637 33.5678'
        + ' 4.00018 32.3359 4.6879L3.33859 20.8759C2.10669 21.5636 1.59199 23.0448'
        + ' 2.18898 24.1842L4.89134 29.3421C7.35515 27.9666 10.3204 28.699 11.5144'
        + ' 30.9779C12.7083 33.2567 11.6789 36.2191 9.21512 37.5946L11.9175'
        + ' 42.7524C12.2042 43.2996 12.7141 43.6932 13.3351 43.8465C13.9562 43.9999'
        + ' 14.6374 43.9006 15.229 43.5703L44.2263 27.3823C45.4582 26.6946 45.9729'
        + ' 25.2134 45.3759 24.074L42.6735 18.9162C40.2097 20.2916 37.2445 19.5592'
        + ' 36.0505 17.2803Z'
        }
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
