import React from 'react';

/**
 * @returns {JSX.Element} Rocket SVG
 */
export default function Rocket() {
  return (
    <svg
      viewBox='0 0 240 546'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_8765_229571)'>
        <path
          d='M91.8366 371C81.4338 394.082 80.0875 418.649 81.4338 440.802C82.7188 437.584 87.8591 429.849 89.3889 426.569C92.0202 512.708 99.4246 540.554 101.75 546C102.484 543.03 103.953 497.795 103.953 486.037C103.953 477.559 104.198 451.507 104.259 439.441C108.726 449.713 110.256 451.507 112.642 456.644C116.314 435.356 116.314 404.787 108.175 374.527L91.8366 371Z'
          fill='#FFBA3F'
        />
        <path
          d='M149.163 371C159.566 394.082 160.912 418.649 159.566 440.802C158.281 437.584 153.141 429.849 151.611 426.569C148.98 512.708 141.575 540.554 139.25 546C138.516 543.03 137.047 497.795 137.047 486.037C137.047 477.559 136.802 451.507 136.741 439.441C132.274 449.713 130.744 451.507 128.358 456.644C124.686 435.356 124.686 404.787 132.825 374.527L149.163 371Z'
          fill='#FFBA3F'
        />
        <path
          d='M116 352C116 357.262 115.69 372.202 111.343 374.319C108.238 375.831 88.4921 374.44 86.8155 373.835C80.9165 371.718 82.0963 361.92 82.0963 357.807L116 352Z'
          fill='#AA0000'
        />
        <path
          d='M102.904 355L82.0941 357.58C82.0941 361.758 80.9414 371.71 86.705 373.86C88.0397 374.351 101.63 375.396 108 374.843C106.423 369.13 103.207 355.737 102.904 355Z'
          fill='#E60000'
        />
        <path
          d='M124 352C124 357.262 124.187 372.202 128.55 374.319C131.666 375.831 151.484 374.44 153.167 373.835C159.087 371.718 157.903 361.92 157.903 357.807L124 352Z'
          fill='#AA0000'
        />
        <path
          d='M186.868 320.471C186.868 320.471 230.612 383.026 233.807 371.607C244.681 332.635 238.967 270.7 233.807 253.324C228.646 235.948 202.105 211 202.105 211C202.105 211 172.676 305.887 186.868 320.471Z'
          fill='#FFFCD9'
        />
        <path
          d='M53.1103 320.471C53.1103 320.471 9.38438 383.026 6.19091 371.607C-4.67915 332.635 1.03224 270.7 6.19091 253.324C11.3496 235.948 37.8799 211 37.8799 211C37.8799 211 67.358 305.887 53.1103 320.471Z'
          fill='#FFFCD9'
        />
        <path
          d='M81.9593 360C66.5631 359.26 61.7634 346.377 53.9718 321.904C44.0609 290.651 31.0333 229.438 42.4402 163.171C58.1481 71.5685 98.9762 0 119.795 0H120.232C135.441 0 181.879 71.5685 197.587 163.171C208.931 229.5 195.966 290.651 186.055 321.904C178.264 346.377 173.402 359.26 158.068 360C158.068 360 139.181 354.76 119.359 354.76C99.5372 354.76 81.9593 360 81.9593 360Z'
          fill='#FF4436'
        />
        <path
          d='M196.606 163.138C181.249 72.6289 136.224 1.66467 120.619 0C120.557 0.308272 120.434 0.616544 120.372 0.924816C84.7223 103.518 70.5363 257.715 103.843 355.746C104.644 355.746 118.399 354.759 119.2 354.759C138.814 354.759 157.502 360 157.502 360C172.737 359.26 177.486 346.374 185.196 321.898C195.003 290.7 207.894 229.478 196.606 163.138Z'
          fill='#E60000'
        />
        <path
          d='M197.898 267.877C199.263 258.552 200.318 248.552 201 238.061C201 238.061 165.51 228 123.94 228C82.3696 228 39 236.282 39 236.282C39.6205 247.448 40.7373 258.061 42.2263 268C42.2263 268 84.6653 260.025 125.491 259.963C162.222 259.902 197.836 267.693 197.898 267.877Z'
          fill='#FFA300'
        />
        <path
          d='M118.5 205C146.39 205 169 182.39 169 154.5C169 126.61 146.39 104 118.5 104C90.6096 104 68 126.61 68 154.5C68 182.39 90.6096 205 118.5 205Z'
          fill='#AA0000'
        />
        <path
          d='M118.5 185C135.345 185 149 171.345 149 154.5C149 137.655 135.345 124 118.5 124C101.655 124 88 137.655 88 154.5C88 171.345 101.655 185 118.5 185Z'
          fill='#FFFCD9'
        />
        <path
          d='M119 68C129.493 68 138 59.4934 138 49C138 38.5066 129.493 30 119 30C108.507 30 100 38.5066 100 49C100 59.4934 108.507 68 119 68Z'
          fill='#AA0000'
        />
        <path
          d='M119 331C129.493 331 138 322.493 138 312C138 301.507 129.493 293 119 293C108.507 293 100 301.507 100 312C100 322.493 108.507 331 119 331Z'
          fill='#AA0000'
        />
      </g>
      <defs>
        <clipPath id='clip0_8765_229571'>
          <rect
            width='240'
            height='546'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>

  );
}
