import React from 'react';

/**
 * @returns {JSX.Element} BackArrow Component
 */
export default function BackArrow() {
  return (
    <svg
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.24774 20H32.748'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.873 30.625L7.24792 19.9999L17.873 9.37474'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
