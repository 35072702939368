import React from 'react';

/**
 * @returns {JSX.Element} FailIcon svg
 */
export default function FailIcon() {
  return (
    <svg
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        stroke='none'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z'
        fill='#EB9700'
      />
      <path
        d='M16.1333 16.1328L27.8667 27.8662M27.867 16.1328L16.1336 27.8662'
        stroke='white'
        strokeWidth='2.93333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
